import React, { useState } from "react";

import { RxDotFilled } from "react-icons/rx";
import {
  // FaTasks,
  FaGraduationCap,
  FaHistory,
  FaLaptopCode,
  FaPinterestSquare,
} from "react-icons/fa";
import parse from "html-react-parser";
import {
  EducationData,
  EmploymentHistoryData,
  // ProgrammingSkillsData,
  ProjectsData,
  interestsData,
  TechnicalSkills,
} from "./data";
import "./resume.scss";
export default function ResumePage() {
  const [resume, setResume] = useState("history");

  const getResume = () => {
    // Education
    if (resume === "education") {
      return (
        <div>
          {EducationData.map((data, index) => (
            <div key={index}>
              <div className="resume-education-row">
                <div className="resume-education-detail">
                  <p>
                    <span>
                      <RxDotFilled />
                    </span>
                    <b className="resume-education-detail-title">
                      {data.location}
                    </b>
                  </p>
                </div>
                <div className="resume-education-year">
                  <span>{data.year}</span>
                </div>
              </div>
              <div className="resume-education-title">
                <p>{data.title}</p>
              </div>
            </div>
          ))}
        </div>
      );
    }
    // Work history
    else if (resume === "history") {
      return (
        <div>
          {EmploymentHistoryData.map((data, index) => (
            <div key={index}>
              <div className="resume-education-row">
                <div className="resume-education-detail">
                  <p>
                    <span>
                      <RxDotFilled />
                    </span>
                    <b className="resume-education-detail-title">
                      {data.company}
                    </b>
                  </p>
                </div>
                <div className="resume-education-year">
                  <span>{data.year}</span>
                </div>
              </div>
              <div className="resume-education-title">
                <p>{parse(data.description)}</p>
              </div>
            </div>
          ))}
        </div>
      );
    }
    // Programming skills
    else if (resume === "skills") {
      return (
        <>
          {/* <div className="row">
            {ProgrammingSkillsData.map((data, index) => (
            <div key={index} className="col-md-6">
              <div className="resume-education-row">
                <div className="resume-education-detail">
                  <p>
                    <span>
                      <RxDotFilled />
                    </span>
                    <b className="resume-education-detail-title">
                      {data.programming}
                    </b>
                  </p>
                  <div className="resume-education-title bar">
                    <div className="bar resume-education" key={index}>
                      <div
                        className="positive"
                        style={{ width: `${Math.max(data.percentage)}%` }}
                      >
                        {`${Math.max(data.percentage)}%`}
                      </div>
                      <div
                        className="negative"
                        style={{ width: `${Math.max(100 - data.percentage)}%` }}
                      >
                        {`${Math.max(100 - data.percentage)}%`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div> */}

          <div className="resume-technical-skills">
            {/* <ul>
              <li>
                <b>Front-end:</b> Expert in building scalable, performant UIs
                using React.js, Next.js, React Native, and TypeScript, with deep
                knowledge of Redux Toolkit and modern JavaScript (ES6+).
              </li>
              <li>
                <b>Back-end:</b> Proficient in architecting robust back-end
                systems using Python, Node.js with Express and NestJs
                frameworks.
              </li>
              <li>
                <b>Database Management:</b> Skilled in MongoDB, MySQL, and
                PostgreSQL, with a focus on efficient schema design and
                performance optimization.
              </li>
              <li>
                <b>Tools & Technologies:</b> Docker, Kubernetes, Git, AWS, REST
                APIs, GraphQL, Jest, Cypress Microservices Architecture.
              </li>
              <li>
                <b>DevOps & Cloud:</b> Extensive experience with AWS (EC2, RDS,
                S3, Lambda, etc.), Docker, Kubernetes, CircleCI, and GitHub
                Actions for CI/CD, automated testing, and scalable cloud
                deployments.
              </li>
              <li>
                <b> Testing & QA:</b> Strong advocate of TDD and BDD
                methodologies with expertise in Jest, Cypress, and end-to-end
                testing suites.
              </li>
            </ul> */}
            {TechnicalSkills.map((data, index) => (
              <div key={index}>
                <div className="resume-education-row">
                  <div className="resume-education-detail">
                    <p>
                      <span>
                        <RxDotFilled />
                      </span>
                      <b className="resume-education-detail-title">
                        {data.title}
                      </b>
                    </p>
                    <div className="resume-education-title">
                      <p>{data.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      );
    }
    // Projects
    else if (resume === "projects") {
      return (
        <div>
          {ProjectsData.map((data, index) => (
            <div key={index}>
              <div className="resume-education-row">
                <div className="resume-education-detail">
                  <p>
                    <span>
                      <RxDotFilled />
                    </span>
                    <b className="resume-education-detail-title">
                      {data.project}
                    </b>
                  </p>
                  <div className="resume-education-title">
                    <p>
                      <b>{data.title}</b>
                      <br />
                      {data.content}
                    </p>
                  </div>
                </div>
                <div className="resume-education-year">
                  <span>{data.year}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    // Interests
    else if (resume === "interests") {
      return (
        <div>
          {interestsData.map((data, index) => (
            <div key={index}>
              <div className="resume-education-row">
                <div className="resume-education-detail">
                  <p>
                    <span>
                      <RxDotFilled />
                    </span>
                    <b className="resume-education-detail-title">
                      {data.title}
                    </b>
                  </p>
                  <div className="resume-education-title">
                    <p>{data.content}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="resume-main">
      <div className="container">
        <div className="global-header">
          <h1 className="global-title">Resume</h1>
          <h5 className="global-sub-title">My Formal Bio Details</h5>
          <div className="global-line">
            <span></span>
          </div>
        </div>
        <div className="resume-section">
          <div className="row">
            <div className="resume-list col-md-5">
              <div className="resume-list-down">
                <ul>
                  <li
                    className={resume === "history" && "resume-list-active"}
                    onClick={() => setResume("history")}
                  >
                    <span className="resume-list-icon">
                      {" "}
                      <FaHistory />
                    </span>{" "}
                    Works
                  </li>
                  <li
                    className={resume === "education" && "resume-list-active"}
                    onClick={() => setResume("education")}
                  >
                    <span className="resume-list-icon">
                      <FaGraduationCap />
                    </span>{" "}
                    Education
                  </li>

                  <li
                    className={resume === "skills" && "resume-list-active"}
                    onClick={() => setResume("skills")}
                  >
                    <span className="resume-list-icon">
                      {" "}
                      <FaLaptopCode />
                    </span>{" "}
                    Skills
                  </li>
                  {/* <li
                  className={resume === "projects" && "resume-list-active"}
                  onClick={() => setResume("projects")}
                >
                  <span className="resume-list-icon">
                    {" "}
                    <FaTasks />
                  </span>{" "}
                  Projects
                </li> */}
                  <li
                    className={resume === "interests" && "resume-list-active"}
                    onClick={() => setResume("interests")}
                  >
                    <span className="resume-list-icon">
                      {" "}
                      <FaPinterestSquare />
                    </span>{" "}
                    Interests
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">{getResume()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
