export const EducationData = [
  {
    location: "University of East London",
    title: "MSc (Master) Data Science",
    year: "2017 – 2018",
  },
  {
    location: "University of East London",
    title: "BSc (Hons) Computer Science",
    year: "2014 – 2017",
  },
  {
    location: "South Essex College, Southend-On-Sea",
    title: "ICT Professional Competence level 2 & 3 and Cisco (CCNA)",
    year: "2011 – 2013",
  },
];

export const EmploymentHistoryData = [
  {
    company: "Senior Software Engineer, Ambie, UK",
    year: "April 2022 – Present",
    description: `Ambie helps 2,000+ hospitality businesses to take control of their background music, creating brand consistency and positive guest experiences which drive sales.
    <ul>
    <li>Designed and implemented a highly available music streaming backend service using Node.js (Express, NestJs) TypeScript, and GraphQL, included MongoDB, MySQL, and PostgreSQL, scaling it to handle thousands of concurrent users with fault tolerance and load balancing strategies. Integrated a microservices architecture to enhance scalability and flexibility across different services.</li>
    <li>Implemented user-friendly CMS and web player with React.js and Next.js and ensure alignment of technical solutions with business requirements. Implemented React Native mobile app for clients. Also, spearheaded initiatives for real-time updates using WebSockets and developed automated test suites for comprehensive testing to validate API, back-end and Front-end components.</li>
    <li>Led the design of distributed, fault-tolerant systems with event-driven architectures, ensuring system reliability and high uptime.</li>
    <li>Deepened backend optimisations, focusing on performance tuning, caching strategies, database query optimisation, and memory management to enhance system responsiveness.</li>
    <li>Mastered cloud platforms, predominantly AWS, to design a scalable cloud infrastructure . Spearheaded containerisation efforts with Docker and Kubernetes. Integrated GitHub Actions and CircleCI for CI/CD, ensuring smooth automated testing and continuous deployment pipelines.</li>
    <li>Integrated analytics and monitoring tools to enhance system performance tracking and user behavior analysis.</li>
    <li>Led and mentored a team of engineers, driving collaboration across backend, frontend, and design teams to deliver cohesive and business-aligned technical solutions.</li>
     </ul>`,
  },
  {
    company: "Software Engineer, Doctaly, UK",
    year: "Feb 2021 – Feb 2022",
    description: `Doctaly Assist is a highly flexible Remote Patient Monitoring platform developed in collaboration with the NHS, patient groups and leading UK academic institutions, currently covering over two million patients in South East London with a range of Long Term Conditions.
    <ul>
    <li>Engineered backend services using Node.js (Express) with MongoDB and PostgreSQL, ensuring seamless integration and high performance for the Remote Patient Monitoring platform used by over two million patients.</li>
    <li>Developed and optimized GraphQL and RESTful APIs, collaborating closely with backend teams to deliver scalable and robust solutions.</li>
    <li>Led cross-functional collaboration with designers, product managers, and QA engineers, driving the successful implementation of key features and ensuring alignment with project objectives.</li>
    <li>Proactively implemented new features in an existing React.js and node.js project, demonstrating leadership in enhancing functionality and user experience</li>
     </ul>`,
  },
  {
    company: "Software Developer, Johnson Care Group, UK",
    year: "Mar 2016 – Feb 2021",
    description: `Johnson Care Group provide residents with a comfortable, safe, and supportive environment where they can receive the care and assistance, they need to maintain their independence and quality of life.
    <ul>
    <li>Developed and maintained a comprehensive Digital Care Planning , Digital Daily Care Records and CMS platform using Node.js, React.js, MongoDB and PostgreSQL, enhancing user experience and system scalability.</li>
    <li>Led the development of a React Native mobile app for managing schedules, booking leaves, and handling user requests, significantly improving user engagement and operational efficiency.</li>
    </ul>`,
  },
];

export const ProgrammingSkillsData = [
  {
    programming: "React JS",
    percentage: 95,
  },
  {
    programming: "React Native",
    percentage: 90,
  },
  {
    programming: "Next JS",
    percentage: 85,
  },
  {
    programming: "JavaScript",
    percentage: 80,
  },
  {
    programming: "Typescript",
    percentage: 90,
  },
  {
    programming: "MongoDB",
    percentage: 84,
  },
  {
    programming: "SQL",
    percentage: 95,
  },
  {
    programming: "Node JS (Express, Nest)",
    percentage: 98,
  },
  {
    programming: "AWS",
    percentage: 75,
  },
  {
    programming: "Linux",
    percentage: 45,
  },
];

export const ProjectsData = [
  {
    project: "Ambie (UK)",
    title: "MSc (Master) Data Science",
    content: "Message here ...",
    year: "2017 – 2018",
  },
  {
    project: "Doctaly (UK)",
    title: "BSc (Hons) Computer Science",
    content: "Message here ...",
    year: "2014 – 2017",
  },
  {
    project: "Jonshon Care Group (UK)",
    title: "ICT Professional Competence level 2 & 3 and Cisco (CCNA)",
    content: "Message here ...",
    year: "2011 – 2013",
  },
];

export const interestsData = [
  {
    title: "Technology",
    content:
      "Stay up to date with the latest advancements in technology, and I enjoy building websites and experimenting with programming languages in my free time.",
  },
  {
    title: "Football",
    content:
      "I am passionate about football and I play in a local league as well as following both leagues and international competitions.",
  },
  {
    title: "Music",
    content: "Play drum and enjoy listening to music.",
  },
];

export const TechnicalSkills = [
  {
    title: "Front-end",
    content:
      "Expert in building scalable, performant UIs using React.js, Next.js, React Native, and TypeScript, with deep knowledge of Redux Toolkit and modern JavaScript (ES6+).",
  },
  {
    title: "Back-end",
    content:
      "Proficient in architecting robust back-end systems using Python, Node.js with Express and NestJs frameworks.",
  },
  {
    title: "Database Management",
    content:
      "Skilled in MongoDB, MySQL, and PostgreSQL, with a focus on efficient schema design and performance optimization.",
  },
  {
    title: "Tools & Technologies",
    content:
      "Docker, Kubernetes, Git, AWS, REST APIs, GraphQL, Jest, Cypress Microservices Architecture.",
  },
  {
    title: "DevOps & Cloud",
    content:
      "Extensive experience with AWS (EC2, RDS, S3, Lambda, etc.), Docker, Kubernetes, CircleCI, and GitHub Actions for CI/CD, automated testing, and scalable cloud deployments.",
  },
  {
    title: "Testing & QA",
    content:
      "Strong advocate of TDD and BDD methodologies with expertise in Jest, Cypress, and end-to-end testing suites.",
  },
];
