import Typical from "react-typical";
import { FaDownload, FaHandsHelping } from "react-icons/fa";

import { SocialMedial } from "./components/social-medial/SocialMedial";
import { GlobalButton } from "../../components/button";

import "./home.css";
export default function HomePage() {
  const handleDownload = () => {
    const pdfUrl = "/resume/Cyrille_Hounvio_Cv.pdf";

    // Create an anchor element
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = "CyrilleHounvioCV.pdf";

    // Trigger the download
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
  };

  return (
    <div className="home-main-header">
      <div className="home-container">
        <div className="home-main home-parent container">
          <div className="home-content">
            <div className="home-details">
              <div className="home-social-medial">
                <SocialMedial />
              </div>
              <div className="home-content">
                <div className="profile-name">
                  <h1>Cyrille Hounvio</h1>
                </div>
                <div className="home-role">
                  <h5 className="home-skills">
                    <Typical
                      loop={Infinity}
                      steps={[
                        "Frontend (React.js and Next.js) ⌨️",
                        3000,
                        "Backend (Node.js and Python) 💻",
                        3000,
                        "Mobile App (React Native) 📱",
                        3000,
                        "Database (MongoDB, and SQL) 💽",
                        3000,
                      ]}
                    />
                  </h5>
                </div>
                <div className="home-discription">
                  <p>
                    Senior Software Engineer with over 8 years of experience in
                    full-stack development, specialising in Node.js (Express,
                    NestJS) for backend and React.js, Next.js for frontend.
                  </p>

                  <p>
                    Proven track record in delivering scalable, high-performance
                    applications across diverse industries including healthcare
                    and music.
                  </p>

                  <p>
                    Adept at leading development teams, architecting solutions,
                    and driving continuous improvement in software development
                    practices.
                  </p>
                </div>
                <div className="home-button">
                  <GlobalButton
                    format="orange"
                    to="contactPage"
                    small
                    rightIcon={<FaHandsHelping />}
                    className="home-button-greeting"
                  >
                    Greeting
                  </GlobalButton>
                  <GlobalButton
                    format={"orange"}
                    small
                    rightIcon={<FaDownload />}
                    className="home-button-resume"
                    onClick={handleDownload}
                  >
                    Get Resume
                  </GlobalButton>
                </div>
              </div>
            </div>
          </div>
          <div className="home-picture">
            <div className="home-picture-layout">
              <div className="home-picture-background"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#202A44"
            fill-opacity="1"
            d="M0,96L40,117.3C80,139,160,181,240,208C320,235,400,245,480,234.7C560,224,640,192,720,176C800,160,880,160,960,176C1040,192,1120,224,1200,218.7C1280,213,1360,171,1400,149.3L1440,128L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
          ></path>
        </svg>
      </div>
    </div>
  );
}
